import React, { Component, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { routes } from './routes';
import { setConfig } from './redux/actions';

// Themes
import './assets/scss/DefaultTheme.scss';
import 'animate.css';
import './global.css';


/**
 * Main app component
 */
class App extends Component {
  /**
 * Returns the layout component based on different properties
 * @param {*} props 
 */
  constructor() {
    super()
    this.state = {
      users: [],
      called: false
    }
  }

  
  getHeightAndWidth = () => this.props.setConfigAction({height: window.innerHeight, width: window.innerWidth})
  setScroll = () => this.props.setConfigAction({scroll: window.pageYOffset})

  componentDidMount() {
    window.addEventListener('DOMContentLoaded', this.getHeightAndWidth)
    window.addEventListener('load', this.getHeightAndWidth)
    window.addEventListener('resize', this.getHeightAndWidth)
    window.addEventListener('scroll', this.setScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('DOMContentLoaded', this.getHeightAndWidth)
    window.removeEventListener('load', this.getHeightAndWidth)
    window.removeEventListener('resize', this.getHeightAndWidth)
    window.removeEventListener('scroll', this.setScroll)
  }

  render() {
   return (<Suspense fallback={<div className="loading" />}>
      <BrowserRouter>
        <Switch>
          {routes.map((route, key) => <Route path={route.path} exact={route.exact} key={key} component={route.component} />)}
        </Switch>
      </BrowserRouter>
    </Suspense>);
  }
}



export default connect(null, {setConfigAction: setConfig})(App);