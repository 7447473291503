// @flow
import cookie from "react-cookies";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import {
	LOGIN_USER,
	GET_USERS,
	LOGOUT_USER,
	REGISTER_USER,
} from "../../constants/actionTypes";

import {
	loginUserSuccess,
	loginUserFailed,
	registerUserFailed,
	registerUserSuccess,
	allUsers,
} from "./actions";
// import firebase from '../../helpers/config'

/**
 * Sets the session
 * @param {*} user
 */
const setSession = (user) => {
	if (user) {
		cookie.save("user", JSON.stringify(user), {
			path: "/",
		});
	} else {
		cookie.remove("user");
	}
};
/**
 * Login the user
 * @param {*} payload - username and password
 */
// function* login({
//         payload: {
//             username,
//             password
//         }
//     }) {
//         try {
//             username = username.toLowerCase()
//             let res = yield call(() => firebase.auth().signInWithEmailAndPassword(username, password)
//                 .then(async response => {
//                     let _USER = [];
//                     let userData = response.user;
//                     let auth_token = await userData.getIdToken(true).then(res => res).catch(Err => console.log(Err));
//                     await firebase.firestore().collection('users').get()
//                         .then(users => {
//                             users.forEach(user => {
//                                 let usr = user.data();
//                                 if (usr.email == username) {
//                                     _USER.push({
//                                         id: usr.id,
//                                         uid: usr.uid,
//                                         profile_img: usr.profile_img,
//                                         profile_logo: usr.profile_logo,
//                                         email: usr.email,
//                                         name: usr.name,
//                                         surname: usr.surname,
//                                         address: usr.address,
//                                         social: usr.social,
//                                         profession: usr.profession,
//                                         passCode: usr.passCode,
//                                         workAt: usr.workAt,
//                                         role: "User",
//                                         token: auth_token
//                                     })
//                                 }
//                             })
//                         })
//                         .catch(err => {
//                             throw err;
//                         })
//                     if (_USER.length == 0) {
//                         throw Error("User not found!!")
//                     } else {
//                         return _USER[0];
//                     }
//                 })
//                 .catch(err => {
//                     throw err
//                 })
//             );
//             setSession(res);
//             yield put(loginUserSuccess(res));
//         } catch (error) {
//             setSession(null);
//             yield put(loginUserFailed(error.message));
//         }
//     }
/**
 * Register the user
 * @param {*} payload - username and password
 */
// function* registerUser({
//     payload: {
//         id,
//         name,
//         lastname,
//         email,
//         password
//     }
// }) {

//     try {
//         let res = yield call(() => firebase.auth().createUserWithEmailAndPassword(email, password)
//             .then(async() => {
//                 return await firebase.firestore().collection('users').get().then(users => {
//                         let message = {
//                             message: 'User has been created!!'
//                         };
//                         users.forEach(user => {
//                             let usr = user.data()
//                             if (usr.id == id) {
//                                 usr = {
//                                     id,
//                                     email,
//                                     uid: usr.uid,
//                                     name,
//                                     surname: lastname,
//                                     address: '',
//                                     social: [],
//                                     mobile: '',
//                                     role: "User",
//                                     createdOn: new Date().toDateString()
//                                 }
//                                 firebase.firestore().collection("users").doc(usr.uid).update(usr)
//                             }
//                         })
//                         return message
//                     })
//                     .catch((err) => {
//                         throw err
//                     })
//             })
//             .catch(err => {
//                 throw err
//             }));
//         yield put(registerUserSuccess(res.message));
//     } catch (error) {
//         yield put(registerUserFailed(error.message));
//     }
// }

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({ payload: { history } }) {
	try {
		setSession(null);
		yield call(() => {
			history.push("/");
		});
	} catch (error) {}
}

// export function* watchLoginUser() {
//     yield takeEvery(LOGIN_USER, login);
// }

export function* watchLogoutUser() {
	yield takeEvery(LOGOUT_USER, logout);
}

// export function* watchRegisterUser() {
//     yield takeEvery(REGISTER_USER, registerUser);
// }

function* authSaga() {
	yield all([
		// fork(watchLoginUser),
		// fork(watchRegisterUser),
		fork(watchLogoutUser),
	]);
}

export default authSaga;
