// @flow
import {
    ENQUIRY_ERROR,
    ENQUIRY_SUCCESS,
    QUOTE_ERROR,
    QUOTE_SUCCESS,
    SET_CONFIG_APP, SET_ENQUIRY, SET_QUOTE
} from '../../constants/actionTypes';

const INIT_STATE = {
    width: window.innerWidth || 0,
    height: window.innerHeight || 0,
    loading: false,
    success: null,
    error: null
};

const Auth = (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_CONFIG_APP:
            return {
                ...state, ...action.payload
            };
        case SET_ENQUIRY:
            return {
                ...state,
                loading: true,
                success: null,
                error: null
            };
        case ENQUIRY_SUCCESS:
            return {
                success: action.payload,
                error: null,
                loading: false
            };
        case ENQUIRY_ERROR: 
        return {
            ...state,
            success: null,
            error: action.payload,
            loading: false
        }
        case SET_QUOTE:
            return {
                ...state,
                loading: true,
                success: null,
                error: null
            };
        case QUOTE_SUCCESS:
            return {
                success: action.payload,
                error: null,
                loading: false
            };
        case QUOTE_ERROR: 
        return {
            ...state,
            success: null,
            error: action.payload,
            loading: false
        }
        default:
            return {
                ...state
            };
    }
}

export default Auth;