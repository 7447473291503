import Hand from "../assets/images/hand.png";
import Support from "../assets/images/support.png";
import Rate from "../assets/images/rating.png";
import Imagination from "../assets/images/imagination.png";
import Relationship from "../assets/images/relationship.png";
import Route from "../assets/images/route.png";
import Culture from "../assets/images/culture.png";
import Commitment from "../assets/images/commitment.png";
import Values from "../assets/images/values.png";
import VAN from "../assets/images/van.png";
import TONS from "../assets/images/tons.png";
import COUNTRIES from "../assets/images/countries.png";
import Profile from "../assets/images/owners/ceo.jpg";

export const SERVER_URL = "https://api.seacliff.com.pk";
// export const SERVER_URL = 'http://localhost:8081'

export const Menu = [
	{ label: "Home", to: "/" },
	{ label: "Services", to: "/services" },
	{ label: "Our Company", to: "/company" },
	{ label: "Tracking", to: "/tracking" },
	{ label: "Contact Us", to: "/contact-us" },
	{ label: "Get Quote", to: "/quote", type: "button" },
];

export const FAQ1 = [
	{
		id: "faq1",
		label: "What types of service does SeaCliff Shipping Line provide?",
		desc: "SeaCliff Shipping Line provides a number of transport services to customers around the world. Please refer to our Services page for more information.",
	},
	{
		id: "faq2",
		label: "Can you tell me when my shipment will be delivered?",
		desc: "To find out if your shipment has been delivered, who signed for it and other tracking information just type in your GFL consignment number on the Track and Trace page.",
	},
	{
		id: "faq3",
		label: "Which destinations does SeaCliff Shipping Line ship to?",
		desc: "Our Transit Times provides information on all the destinations we can ship to from your location. When you book online or use our shipping tools, we will verify whether we can ship to the required destination.",
	},
	{
		id: "faq4",
		label: "How long have you been in business?",
		desc: "We have been in the freight brokering business since 2014; our staff has decades more in collective experience. Our excellent reputation speaks for itself. We will be here for the long run by doing right by customers just like you.",
	},
	{
		id: "faq5",
		label: "Do you service my industry?",
		desc: "Yes. Our professional logistics team is proud to work with companies both large and small. We can provide you with scheduled service or a one-off shipment. Some of the companies we service include mechanical, industrial, trade show transportation, produce, food and beverage, aerospace and defense industry, plastic, automotive, pharmaceutical, sensitive deliveries, white-glove transportation.",
	},
];
export const FAQ2 = [
	{
		id: "faq6",
		label: "What payment methods do you accept?",
		desc: "Payment terms/options are available upon approval for your freight shipping account with us. We also do accept, Visa, MasterCard, Discover and AMEX.",
	},
	{
		id: "faq7",
		label: "What are the  size limits for a parcel?",
		desc: "Weight and size restrictions vary depending on the service and destination. Does your shipment exceed our standard limits? contact us for more information.",
	},
	{
		id: "faq8",
		label: "Will my parcel be opened for inspection by SeaCliff Shipping Line?",
		desc: `SeaCliff Shipping Line operates an 'unknown shipper policy' for first-time customers. If you are shipping with GFL for the first time, your parcel needs to be left open for inspection by our driver to ensure the content is acceptable for transport through the GFL network. All parcels may be subject to security screening. This may include the use of x-ray, explosive trace detection and other security screening methods. Please note that any shipment may be opened by government agencies (such as customs, border security, or police) while in the care of GFL. GFL has no control over these requests.`,
	},
	{
		id: "faq9",
		label: "From which countries can I import a shipment?",
		desc: `Each country listed in our Transit Times table can export and import shipments. Contact us for additional information, if required. The Express Import option in myGFL may be useful if you import shipments frequently.`,
	},
];
export const ServicesList = [
	{
		Shipping: [
			{
				id: "service-0",
				label: "Full Container Load (FCL)",
				desc: "Sistema Spedizioni can ensure the best quotes for full container loads, special equipment with frequent departures, and guarantees worldwide delivery.",
			},
			{
				id: "service-1",
				label: "Consolidation/Groupage (LCL)",
				desc: "The network of Consolidators we work with allows us to perform well also with our groupage solutions, which is when the shipment is smaller, in terms of space and weight, than the full container",
			},
			{
				id: "service-2",
				label: "Roll-on/roll-off (RO-RO)",
				desc: "We are organised to load trucks and semi-trailer trucks on RO-RO vessels, which feature on-board garages and ramps that allow the cargo to be rapidly and efficiently rolled on and off the vessel.",
			},
		],
		Air: [
			{
				id: "service-1",
				label: "Commercial Expertise",
				desc: "Our Sales Consultant analyse the needs of the client from the very first contact.They ensure that best practice is applied throughout the move process to achieve complete customer satisfaction.",
			},
			{
				id: "service-2",
				label: "Technical Expertise",
				desc: "We use skilled packers and movers using quality packing material to protect your belongings.We are packing your freigh with special materials to contain and protect your items as customer needs change.",
			},
			{
				id: "service-3",
				label: "Localization",
				desc: `We provide stable and high-quality transportation even to the remotest places on our planet. It doesn't matter how far you are, because Air freight reaches places other modes of transport cannot get to.`,
			},
		],
		Railway: [
			{
				id: "service-1",
				label: "Commercial Expertise",
				desc: `Our Sales Consultant analyse the needs of the client from the very first contact.They ensure that best practice is applied throughout the move process to achieve complete customer satisfaction.`,
			},
			{
				id: "service-2",
				label: "Technical Expertise",
				desc: `We use skilled packers and movers using quality packing material to protect your belongings.We are packing your freigh with special materials to contain and protect your items as customer needs change.`,
			},
			{
				id: "service-3",
				label: "Flexible Transportation",
				desc: `A combination of scheduled trains that can be planned well in advance and trains that can be allocated at the last minute ensures the highest possible degree of ordering flexibility for bulk freight transport.`,
			},
		],
	},
];

export const Our_Team = [
	{
		name: "Ali Khan",
		img: Profile,
		title: "CEO & Director",
	},
];
export const Company_Card_List = [
	{
		icon: VAN,
		label: "Delivered Package",
		num: "5,731",
		desc: "We strongly support best practice sharing across our operations around the world and across various industrial sectors.",
		button: "Learn More",
	},
	{
		icon: COUNTRIES,
		label: "Countries covered",
		num: "123",
		desc: "As one of the world’s leading supply chain management companies, we design and implement industry-leading solutions.",
		button: "Learn More",
	},
	{
		icon: TONS,
		label: "Tons of goods",
		num: "731",
		desc: "Our commitment to sustainability helps us reduce waste and share the benefits with our customers.",
		button: "Learn More",
	},
];

export const WhyChooseUs_Lists = [
	{
		label: "Reducing Customer Freight Spend",
		desc: "Our overall combined freight volumes managed on behalf of our customers allows us to aggressively negotiate and secure preferred transportation rates.",
		img: Hand,
	},
	{
		label: "Superior Customer Service",
		desc: "We recognize that each customer may be different with unique requirements so we provide our customer with the personalized service touch.",
		img: Support,
	},
	{
		label: "In Depth Industry Knowledge",
		desc: "Our team of professionals understand the importance of staying on top of industry news, challenges, and game changing events so we can assess the impact to our customers.",
		img: Rate,
	},
	{
		label: "Collaborative Customer Relationships",
		desc: "For Sea Cliff Shipping Line, meeting and exceeding the expectations of our customers is the measuring stick of our success.",
		img: Relationship,
	},
	{
		label: "Forward-thinking",
		desc: "We provide 24-hour monitoring of consignments from our control centres, which are manned by our expert multilingual team. We also provide real-time satellite tracking.",
		img: Imagination,
	},
	{
		label: "Real-Time Tracking",
		desc: "We seek to anticipate market dynamics to allow us to further innovate for our customers.",
		img: Route,
	},
];

export const Carousel_Responsive = {
	desktop: {
		breakpoint: {
			max: 3000,
			min: 1024,
		},
		items: 1,
		slidesToSlide: 1,
	},
	mobile: {
		breakpoint: {
			max: 464,
			min: 0,
		},
		items: 1,
		slidesToSlide: 1,
	},
	tablet: {
		breakpoint: {
			max: 1024,
			min: 464,
		},
		items: 1,
		slidesToSlide: 1,
	},
};

export const Achievements = [
	{
		label: "Years of experience",
		from: 0,
		to: 12,
	},
	{
		label: "Countries we work in",
		from: 0,
		to: 565,
	},
	{
		label: "Companies we work in ",
		from: 0,
		to: 872,
	},
];

export const OurMissionList = [
	{
		label: "Our Culture",
		desc: "SeaCliff Shipping Line Logistics is committed to serving our communities and our customers with a focus on safety and security.",
		img: Culture,
	},
	{
		label: "Our Commitment",
		desc: "To achieve our goals we adopt a client perspective to our business and endeavour to think and act from their point of view.",
		img: Commitment,
	},
	{
		label: "Our Values",
		desc: "SeaCliff Shipping Line invest in insight to get to the heart of our customers’ challenges. We are open and transparent in the way we work.",
		img: Values,
	},
];
