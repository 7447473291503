// @flow
import {
    LOGIN_USER,
    GET_USERS,
    SET_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAILED,
    LOGOUT_USER,
    REGISTER_USER,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAILED,
    FORGET_PASSWORD,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_FAILED,
    VERIFY_USER,
    UPDATE_USER
} from '../../constants/actionTypes';

import {
    getLoggedInUser
} from '../../helpers/authUtils';

const INIT_STATE = {
    user: getLoggedInUser(),
    loading: false,
    users: [],
    verified: false
};

const Auth = (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return {
                ...state,
                loading: true
            };
        case VERIFY_USER:
            return {
                ...state,
                verified: action.payload
            };
        case UPDATE_USER:
            return {
                ...state,
                user: action.payload,
                loading: false
            };
        case GET_USERS:
            return {
                ...state,
                user: action.payload,
                loading: false
            }
        case SET_USER:
            return {
                ...state,
                user: action.payload,
                loading: false
            }
        case LOGIN_USER_SUCCESS:
            return {
                ...state,
                user: action.payload,
                loading: false,
                error: null
            };
        case LOGIN_USER_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case REGISTER_USER:
            return {
                ...state,
                loading: true
            };
        case REGISTER_USER_SUCCESS:
            return {
                ...state,
                success: action.payload,
                loading: false,
                error: null
            };
        case REGISTER_USER_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false,
                success: null
            };
        case LOGOUT_USER:
            return {
                ...state,
                user: null
            };
        case FORGET_PASSWORD:
            return {
                ...state,
                loading: true
            };
        case FORGET_PASSWORD_SUCCESS:
            return {
                ...state,
                passwordResetStatus: action.payload,
                loading: false,
                error: null
            };
        case FORGET_PASSWORD_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        default:
            return {
                ...state
            };
    }
}

export default Auth;