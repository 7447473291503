/* APP */
export const SET_CONFIG_APP = "SET_CONFIG_APP";
// ENQUIRY
export const SET_ENQUIRY = 'SET_ENQUIRY'
export const ENQUIRY_SUCCESS = 'ENQUIRY_SUCCESS'
export const ENQUIRY_ERROR = 'ENQUIRY_ERROR'
// QUOTE
export const SET_QUOTE = 'SET_QUOTE'
export const QUOTE_SUCCESS = 'QUOTE_SUCCESS'
export const QUOTE_ERROR = 'QUOTE_ERROR'
/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const GET_USERS = 'GET_USERS';
export const SET_USER = 'SET_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILED = 'LOGIN_USER_FAILED';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILED = 'REGISTER_USER_FAILED';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAILED = 'FORGET_PASSWORD_FAILED';
export const VERIFY_USER = 'VERIFY_USER';
export const UPDATE_USER = 'UPDATE_USER';