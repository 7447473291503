// import firebase from './config';
import jwtDecode from "jwt-decode";
import cookie from "react-cookies";

const updateSession = (user) => {
	cookie.save("user", JSON.stringify(user), {
		path: "/",
	});
};
/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
	const user = getLoggedInUser();
	if (!user) {
		return false;
	}
	const decoded = jwtDecode(user.token);
	const currentTime = Date.now() / 1000;
	if (decoded.exp < currentTime) {
		console.warn("access token expired");
		return false;
	} else {
		return true;
	}
};

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
	const user = cookie.load("user");
	return user ? (typeof user == "object" ? user : JSON.parse(user)) : null;
};

// const CheckIfExistAlready = async (id) => {
//     return await firebase.firestore().collection('users').get()
//         .then(res => {
//             let exist = false;
//             res.forEach(user => {
//                 let usr = user.data();
//                 if (usr.id == id) {
//                     if (usr.email && usr.email.length !== 0) {
//                         exist = true
//                     }
//                 }
//             })
//             return exist;
//         })
//         .catch((err) => {
//             console.log(err)
//         })
// }
// const CheckIfNew = async (id) => {
//     return await firebase.firestore().collection('users').get()
//         .then(res => {
//             let isNew = false;
//             res.forEach(user => {
//                 let usr = user.data();
//                 if (usr.id == id) {
//                     if (!usr.email) {
//                         isNew = true
//                     }
//                 }
//             })
//             return isNew;
//         })
//         .catch((err) => {
//             console.log(err)
//         })
// }

// const getCurrentUser = async (id) => {
//     return await firebase.firestore().collection('users').get()
//         .then(res => {
//             let USER = []

//             res.forEach(users => {
//                 let user = users.data()
//                 if (user.id == id) {
//                     USER.push(user)
//                 }
//             })
//             return USER;
//         })
//         .catch(err => {
//             console.log(err)
//         })
// }
export {
	isUserAuthenticated,
	getLoggedInUser,
	// CheckIfExistAlready,
	// getCurrentUser,
	updateSession,
	// CheckIfNew
};
