// @flow
import {
    all,
    fork,
    call, 
    put,
    takeEvery
} from 'redux-saga/effects';
import { SERVER_URL } from '../../constants';

import {
    SET_CONFIG_APP, SET_ENQUIRY, SET_QUOTE
} from '../../constants/actionTypes';


import {
    EnquiryError,
    EnquirySuccess,
    QuoteError,
    QuoteSuccess,
    setConfig
} from './actions';



/**
 * Logout the user
 * @param {*} param0 
 */
function* changeConfig({
    payload
}) {
    yield setConfig(payload)
}

const SendEnquiryAsync = async (firstName, lastName, email, phoneNumber, topic, message) => {
    return await fetch(SERVER_URL + '/enquiry', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({firstName, lastName, phoneNumber, email, topic, message})
    })
    .then((res) => res.json())
    .then(res => res)
    .catch(Err => Err)
}

const GetQuoteAsync = async (firstName, lastName, email, phoneNumber, topic, message) => {
    return await fetch(SERVER_URL + '/quote', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({firstName, lastName, phoneNumber, email, topic, message})
    })
    .then((res) => res.json())
    .then(res => res)
    .catch(Err => Err)
}
/**
 * Contact API
 * @param {*} param0 
 */
function* SendEnquiry({
    payload
}) {
    const {firstName, lastName, email, phoneNumber, topic, message} = payload;
    try {
        let response = yield call(SendEnquiryAsync, firstName, lastName, email, phoneNumber, topic, message)
        yield put(EnquirySuccess(response.message))
    }catch(err) {
        yield put(EnquiryError(err.message))
    }
}
function* GetQuote({
    payload
}) {
    const {firstName, lastName, email, phoneNumber, topic, message} = payload;

    try {
        let response = yield call(GetQuoteAsync, firstName, lastName, email, phoneNumber, topic, message)

        yield put(QuoteSuccess(response.message))
    }catch(err) {
        yield put(QuoteError(err.message))
    }
}

export function* watchGetConfig() {
    yield takeEvery(SET_CONFIG_APP, changeConfig);
}
export function* watchSendEnquiry() {
    yield takeEvery(SET_ENQUIRY, SendEnquiry);
}
export function* watchGetQuote() {
    yield takeEvery(SET_QUOTE, GetQuote);
}


function* appSaga() {
    yield all([
        fork(watchGetConfig),
        fork(watchSendEnquiry),
        fork(watchGetQuote)
    ]);
}

export default appSaga;