import React from 'react';
import { Redirect } from "react-router-dom";
import { Route } from 'react-router-dom';

import { isUserAuthenticated, getLoggedInUser } from './helpers/authUtils';

// lazy load all the views
const Home = React.lazy(() =>
    import('./pages/Home'));
const ServicePage = React.lazy(() =>
    import('./pages/Services'));
const CompanyPage = React.lazy(() =>
    import('./pages/Company'));
const TrackingPage = React.lazy(() =>
    import('./pages/Tracking'));
const ContactUs = React.lazy(() =>
    import('./pages/Contact'));
const Quote = React.lazy(() =>
    import('./pages/Quote'));
const FAQ = React.lazy(() =>
    import('./pages/FAQ'));
const NotFound = React.lazy(() =>
    import('./pages/NotFound/notfound'));

// handle auth and authorization

const PrivateRoute = ({ component: Component, roles, ...rest }) => (<Route {...rest}
    render={
        props => {
            const isAuthTokenValid = isUserAuthenticated();
            let profile = props.location.pathname.includes('profile');
            if (!isAuthTokenValid && !profile) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={
                    { pathname: '/', state: { from: props.location } }
                }
                />

            }

            const loggedInUser = getLoggedInUser();
            // check if route is restricted by role
            if (!profile && roles && roles.indexOf(loggedInUser.role) === -1) {
                // role not authorised so redirect to home page
                return <Redirect to={
                    { pathname: '/' }
                }
                />
            }

            // authorised so return component
            return <Component {...props}
            />
        }
    }
/>
)

const routes = [
    { path: '/', name: 'Home', component: Home, route: Route, exact: true },
    { path: '/services', name: 'Services', component: ServicePage, route: Route, exact: true },
    { path: '/company', name: 'Company', component: CompanyPage, route: Route, exact: true },
    { path: '/tracking', name: 'Track', component: TrackingPage, route: Route, exact: true },
    { path: '/contact-us', name: 'ContactUs', component: ContactUs, route: Route, exact: true },
    { path: '/quote', name: 'Quote', component: Quote, route: Route, exact: true },
    { path: '/faq', name: 'FAQ', component: FAQ, route: Route, exact: true },
    { path: '/notfound', name: '404', component: NotFound, route: Route },

]

export { routes, PrivateRoute };