// @flow
import {
    ENQUIRY_ERROR,
    ENQUIRY_SUCCESS,
    QUOTE_ERROR,
    QUOTE_SUCCESS,
    SET_CONFIG_APP,
    SET_ENQUIRY,
    SET_QUOTE
} from '../../constants/actionTypes';

export const setConfig = (payload) => ({
    type: SET_CONFIG_APP,
    payload
});

export const setEnquiry = ({email, firstName, lastName, phoneNumber, topic, message}) => ({
    type: SET_ENQUIRY,
    payload: {email, firstName, lastName, phoneNumber, topic, message}
});

export const EnquirySuccess = (payload) => ({
    type: ENQUIRY_SUCCESS,
    payload
})

export const EnquiryError = (payload) => ({
    type: ENQUIRY_ERROR,
    payload
})


export const setQuote = ({email, firstName, lastName, phoneNumber, topic, message}) => ({
    type: SET_QUOTE,
    payload: {email, firstName, lastName, phoneNumber, topic, message}
});

export const QuoteSuccess = (payload) => ({
    type: QUOTE_SUCCESS,
    payload
})

export const QuoteError = (payload) => ({
    type: QUOTE_ERROR   ,
    payload
})

